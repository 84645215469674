<template>
  <div>
    <div class="classDetail-tz">
      <span class="present">当前位置：</span>
      <span>{{ name }}</span>
      <span class="el-icon-arrow-right"></span>
      <span>导出学生</span>
    </div>
    <div class="classDetail-center clear">
      <div class="class_detail-list">
        <ul>
          <li class="lead-li dis-flex">
            <h3>参考模板：&nbsp</h3>
            <div class="lead-li-btn cursor-pointer" @click="modelup">模板下载</div>
          </li>
          <li class="lead-li dis-flex">
            <h3>上传excel：</h3>
            <div>
              <el-upload
                  ref="upload"
                  class="upload-demo"
                  action="https://kechuang.dbbbkj.top/teacher/upload"
                  :headers="headers"
                  :limit=limit
                  accept=".xlsx,.xls"
                  :on-success="upSuccess"
                  :before-upload="beforeUploads"
                  :on-error="upError"
              >
                <el-button class="lead-li-btn"
                           size="small"
                           style="line-height: 0px"
                           type="text">上传文件
                </el-button>
              </el-upload>
            </div>
          </li>
          <li class="lead-li dis-flex">
            <h3>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</h3>
            <div @click="uploadFiles" class="lead-li-btn cursor-pointer" style="
background: #FF8E42;color: #FFFFFF">确认导入
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'

export default {
  name: "tolead",
  data() {
    return {
      studentfile: '',
      headers: {
        acctid: localStorage.getItem("acctid"),
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      },
      name: '',
      limit: 1,  // 上传excell时，同时允许上传的最大数
    }
  },
  created() {
    this.name = this.$route.query.name
    console.log(this.$route.query)
  },
  methods: {
    // 上传前判断
    beforeUploads(file) {
      console.log(file)
      let result = [];
      for (let i = 0; i < 8; i++) {
        let ranNum = Math.ceil(Math.random() * 25); //生成一个0到25的数字
        //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
        result.push(String.fromCharCode(65 + ranNum));
      }
      console.log(result)
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
      // console.log(extension)
      let size = file.size / 1024 / 1024
      if (extension !== 'xlsx' && extension !== 'xls') {
        this.$message.warning('只能上传后缀是.xlsx和.xls的文件')
      }
      if (size > 10) {
        this.$message.warning('文件大小不得超过10M')
      }
    },
    // 上传成功
    upSuccess(res, file) {
      console.log(res)
      this.studentfile = res.data.url
    },
    // 上传失败
    upError(res, file) {
      if (res) {
        this.$message({
          message: "上传失败",
          duration: 2000,
          type: "warning"
        });
      }
    },

    // 覆盖默认的上传行为，自定义上传的实现
    uploadFiles() {
      let params = {
        studentfile: this.studentfile,
        class_id: this.$route.query.id
      }
      console.log(this.studentfile)
      if (this.studentfile) {
        api.uplead(params).then(res => {
          console.log(res)
          if (res.errcode === 0) {
            this.$message({
              message: '导入成功',
              duration: 2000,
              type: "succeed"
            });
          } else {
            this.$message({
              message: res.errmsg,
              duration: 2000,
              type: "warning"
            });
          }
        })
      } else {
        this.$message.warning('请上传文件')
      }
    },
    // 模板下载
    modelup() {
      let url = this.$route.query.studentdemo
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    }
  },

}
</script>

<style scoped>
.classDetail-center {
  width: 1200px;
  margin: 0 auto;
  min-height: 210px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
}

.classDetail-tz {
  width: 1200px;
  height: 44px;
  line-height: 44px;
  padding-left: 10px;
  margin: 20px auto;
  color: #FF8E42;
  font-size: 16px;
}

.present {
  color: #999999;
}

.class_detail-list {
  width: 98%;
  min-height: 160px;
  margin: 0 auto;
}

.lead-li {
  margin-top: 20px;
  line-height: 32px;
}

.lead-li-btn {
  width: 100px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #FF8E42;
  border-radius: 8px;
  color: #FF8E42;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
}


</style>